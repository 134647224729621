<template>
  <div class="verify">
    <uverify />
  </div>
</template>

<script>
// @ is an alias to /src
import uverify from "@/components/uregister/verify.vue";

export default {
  name: "verify",
  components: {
    uverify
  }
};
</script>