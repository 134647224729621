<template>
  <div class="login-page">
    <div class="logo">
      <img src="@/assets/logo.png" @click="toindex()" />
    </div>
    <div class="bg"></div>
    <div class="ikon">
      <img src="@/assets/ikon.png">
      <div class="login-form">
        <p>注册</p>
        <div class="verify">
          <label class="lab">输入您的验证码</label>
          <input class="input_text" autocomplete="new-password" type="text" name="code" v-model="verify_code" />
          <div class="sendnum">
          <span @click="sendverifycode"  type="danger"  class="sendbtn">{{coded}}</span>
        </div>
        </div>
        <div>
          <label class="lab">创建一个密码</label>
          <input class="input_text" autocomplete="new-password"  type="password" v-model="pwd" name="pwd"/>
        </div>
        <div class="submit">
          <button @click="reg_finish()" type="danger" size="large" class="regbtn">完成注册</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "verify",
  // inject:['app'],
  data() {
    return {
      user: "",
      pwd: "",
      verify_code: "",
      coded:"获取验证码",
      totalTime: 60,
    };
  },
  mounted() {
  },
  methods: {
    sendverifycode(){
      var that=this;
      var phone = this.$route.query.id;
      if(this.coded=='获取验证码'){
                this.coded = '发送中';
                let params={'phone':phone};
        $.ajax({
          async:false,
          type:'post',
          url:this.$store.state.user.q_url+'/api/api/sendSms',
          data:params,
          dataType:'json',
          success:function(res){
              if(that.totalTime==60){
            var bo_clock = window.setInterval(function () {
            that.totalTime--;
            that.coded =that.totalTime + 's';
            if (that.totalTime < 0) {
            window.clearInterval(bo_clock);
            that.coded = '获取验证码';
            that.totalTime = 60;
            }
            },1000)
          }
        },error:function(){
             that.coded = '获取验证码';
              this.$message({
              message: '发送失败',
              type: 'error',
             center:'true'})
        }           
            })}       
    },
    toindex(){
      this.$router.push('./homepage')
    },
    reg_finish(){
      var phone=this.$route.query.id;
      var verify_code=this.verify_code;
      var pwd=this.pwd;
      let params={'phone':phone,'code':verify_code,'password':pwd};
      var results='';
      $.ajax({
          async:false,
          type:'post',
          url:this.$store.state.user.q_url+'/api/api/register',
          data:params,
          dataType:'json',
          success:function(res){
            results=res;
          },
          error : function(res) {
            alert('查询错误');
            }
        })
        if(results.success==true){
        this.$message({
          message: '注册成功',
          type: 'success',
          center:'true'
        });
        this.$router.push('./Login')
        }else(
          this.$message({
          message: '注册失败'+results.message,
          type: 'error',
          center:'true'
        })
        ) 
    }
  },
};
</script>

<style lang="less" scoped>
.login-page {
  top: 80px;
  position: fixed;
  padding: 0px;
  margin: 0;
  font-size: 0px;
  height: 80%;
  width: 100%;
  box-sizing: border-box;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../assets/Login_bg.png) no-repeat;
    
    opacity: 0.9;
    z-index: -10;
  }
}
.logo {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 360px;
  top: -70px;
  > img {
    width: 100%;
    height: 100%;
  }
}

.ikon {
  position: absolute;
  z-index: 5;
  padding: 0;
  align-items: center;
  width: 1000px;
  height: 500px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  overflow: hidden;
  background: rgba(118, 100, 229, 1);
  box-shadow: -2px 0px 24px 0px rgba(61, 49, 226, 0.24);
  > img {
    width: 570px;
    height: 100%;
  }
  .login-form {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    height: 500px;
    width: 430px;
    background-color: #fff;
    padding: 70px;
    box-sizing: border-box;
    > div {
      border-radius: 20px;
      overflow: hidden;
    }
    > p {
      width: 48px;
      height: 24px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 34px;
    }
    .verify{
      position: relative;
    }
    .lab {
      width: 51px;
      height: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 33px;
    }
    .input_text {
      width: 240px;
      height: 40px;
      border: none;
      outline: none;
      text-indent: 10px;
      padding-left: 10px;
      font-size: 18px;
      background: rgba(238, 238, 238, 1);
      border-radius: 20px;
      z-index: 10;
    }
    .sendnum {
      height: 40px;
      width: 80px;
      background: rgba(55, 67, 214, 1);
      border-radius: 0px 20px 20px 0px;
      position: absolute;
      right: 40px;
      top: 36px;
      cursor: pointer;
    }
    .sendbtn {
      width: 100%;
      height: 100%;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 33px;
      margin-left: 10px;
      position: relative;
      top: 4px;
    }
    .submit {
      height: 50px;
      margin: 10px;
      margin-top: 40px;
    }
    .submit .regbtn {
      width: 240px;
      height: 40px;
      background: rgba(55, 67, 214, 1);
      border-radius: 20px;
      display: block;
      color: #fff;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 33px;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>